import React from 'react'
import { string, func } from 'prop-types'

import { ArrowInCircle } from '../Icons'
import * as Styled from './styles/SubmitButton.styles'

const SubmitButton = ({ text, action }) => (
  <Styled.ButtonContainer onClick={action}>
    <Styled.TextLink>{text}</Styled.TextLink>
    <ArrowInCircle />
  </Styled.ButtonContainer>
)

SubmitButton.propTypes = {
  text: string,
  action: func,
}

export default SubmitButton

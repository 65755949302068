import React from 'react'
import { graphql } from 'gatsby'

import SEO from '../../components/SEO'
import Layout from '../../components/Layout'
import HcpdPageHero from '../../components/HealthCareProviderDirectory/HcpdPageHero'
import SliceRenderer from '../../components/ContentBlocks/SliceRenderer'
import LogOutButton from '../../components/Auth/LogOutButton'
import { ProvideAuth } from '../../components/Auth/useAuth'

export const query = graphql`
  {
    prismicHcpdThankYouForRegisteringPage {
      data {
        body_text {
          richText
        }
        facebook_image {
          url
        }
        meta_description
        meta_keywords
        meta_title {
          text
        }
        title {
          text
        }
        twitter_image {
          url
        }
        body {
          ... on PrismicHcpdThankYouForRegisteringPageDataBodyCtaBlock {
            id
            slice_type
            primary {
              cta_link_text
              header {
                richText
                text
              }
              cta_link {
                id
                url
                uid
                type
                link_type
              }
              body_text {
                richText
              }
            }
          }
        }
      }
    }
  }
`

const ThankYouPage = ({ data }) => {
  const doc = data.prismicHcpdThankYouForRegisteringPage?.data
  if (!doc) {
    return null
  }
  const twitterImage = doc.twitter_image?.url ? doc.twitter_image : null
  const facebookImage = doc.facebook_image?.url ? doc.facebook_image : null
  const seoTitle = doc.meta_title?.text
    ? doc.meta_title.text
    : doc.title?.text
    ? doc.title.text
    : 'Thank you for registering!'

  return (
    <ProvideAuth>
      <SEO
        seo={{
          description: doc.meta_description,
          keywords: doc.meta_keywords,
          title: seoTitle,
          twitter_image: twitterImage,
          facebook_image: facebookImage,
        }}
      />
      <Layout>
        <LogOutButton />
        <HcpdPageHero
          title={doc.title?.text ?? 'Thank you for registering!'}
          body={doc.body_text}
          addMarginBottom={{ mobile: '20px', desktop: '80px' }}
        />
        {doc.body &&
          doc.body.map(block => <SliceRenderer key={block.id} block={block} />)}
      </Layout>
    </ProvideAuth>
  )
}

export default ThankYouPage

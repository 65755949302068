import styled from 'styled-components'

export const ButtonContainer = styled.button`
  background-color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: flex-end;
  padding: 14px 0;
  text-decoration-line: none;

  svg .rollover {
    display: none;
  }

  :hover {
    svg .rollover {
      display: inherit;
    }

    svg .normal {
      display: none;
    }
  }
`

export const TextLink = styled.p`
  pointer-events: auto;
  font-size: 1.4rem;
  line-height: 1;
  font-weight: bold;
  letter-spacing: 0.02em;
  color: var(--highlight-blue);
  user-select: none;
  padding: 15px 14px 15px 0;
`
